import React, { Component } from "react";
import './Rewards.sass';

import {OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap';
import Countdown from "react-countdown";

import { ReactComponent as CoinsFull } from "../images/coins-1.svg";
import coins from '../images/coins.svg';
import diamonds from '../images/daimonds.svg';

import discount32 from '../images/discount-32.svg';
import discount36 from '../images/discount-36.svg';


const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span>
        {days} Days {hours}:{minutes}:{seconds}
      </span>
    );
};


class Rewards extends Component {
	
	/* api related code */
	state = {
		loading: true,
		reward: null,
		reward_info: null,
		
	};
	
	async componentDidMount() {
		const apiUrl = process.env.REACT_APP_API_URL;
		const url = apiUrl+"/rewards"; //process.env.REACT_APP_API_URL
		const response = await fetch(url);
		const data = await response.json();
		this.setState({ image_url : data.image_url , reward: data.results.slice(1), reward_first: data.results[0], loading: false});
		
		const rewardsInfo = data.results;
		const rewardsLength = rewardsInfo.length;
		console.log(rewardsInfo);
		console.log(rewardsLength);
		
	}
	/* api related code */
	
	reward_click = (reward_info) => {
		localStorage.setItem('reward', JSON.stringify(reward_info));
		localStorage.setItem('reward_id', reward_info.id);
		window.location="checkout"
	}
	
	
	/* modal popup code */
	constructor(props, context) {
		super(props, context);

		// this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);

		this.state = {
			show: false,
			insufficient_show: false,
		};
		
		/* modal content change code */
		
	}

	
	
	rewardPurchase(reward_val) {
		reward_val.image_url = this.state.image_url;
		localStorage.setItem('reward', JSON.stringify(reward_val));
		localStorage.setItem('reward_id', reward_val.id);
		window.location.href="checkout";
		// this.setState({ show: false, insufficient_show: false });
	}

	confirmation(reward_info) {
		
		var reward_value = reward_info.reward_value;
		
		if(reward_info.currency == 'GoldCoins'){
			var reward_check = localStorage.getItem('coins');
		}else{
			var reward_check = localStorage.getItem('diamonds');
		}
		
		if(Number(reward_value) > Number(reward_check)){
			this.setState({ insufficient_show: true });
		}
		else
		{
			this.setState({	reward_info : reward_info , reward_name : reward_info.name , reward_description : reward_info.description , reward_image : reward_info.image , currency: reward_info.currency });
			this.setState({ show: true });
		}
	}
	
	handleClose() {
		this.setState({ show: false, insufficient_show: false });
	}
	/* modal popup code */
	
	
	
  render() {
	  
	const{ outOfStock } = this.state;  
	
	/* api related code */
	if (this.state.loading) {
      return <div>loading...</div>;
    }

    if (!this.state.reward) {
      return <div>Loading...</div>;
    }
	/* api related code */
	
  
    return (
		<div className="rewards-overlay m-t-15 posr">
			
			<div className="coins-left-box display-flex justify-center">
				<CoinsFull className="coins-full" />
				<div className="coins-left-sub">
					<p className="coins-left-text1 align-left">Coins left</p>
					<p className="coins-left-text2 align-left">{localStorage.getItem('coins')}</p>
				</div>
			</div>
			
			<div className="go-pro-overlay display-flex vertical-align-center">
				<img src={this.state.image_url+this.state.reward_first.image} className="gopro-pic" alt="reward" />
				
				<div className="">
					<p className="go-pro-text align-left">{this.state.reward_first.name}</p>
					<p className="go-pro-text1 align-left">{this.state.reward_first.description}</p>
					<div className="reward-quantity-overlay">
						<div className="reward-quantity-line-box"><div className="reward-quantity-line-sub"></div></div>
						<p className="reward-quantity-text align-right">Only {this.state.reward_first.quantity} left!</p>
					</div>
					<Button className={"go-pro-anchor " + (this.state.reward_first.quantity == 0? "active":"")} variant="primary" onClick={() => this.confirmation(this.state.reward_first)}>
						
						<img src={(this.state.reward_first.currency == "GoldCoins"? coins:diamonds)} className={(this.state.reward_first.currency == "GoldCoins"? "coins-svg-1":"diamonds-svg")} alt={(this.state.reward_first.currency == "GoldCoins"? "coins-svg":"diamonds-svg")} />
						
						<span> {this.state.reward_first.reward_value}</span>
					</Button>
					<span className={"out-of-stock " + (this.state.reward_first.quantity == 0? "active":"")} >OUT OF STOCK</span>
				</div>	
			</div>
			
			<div className="rewards-info-overlay display-flex justify-between wrap pad-10 posr">
				{this.state.reward.map((product, i) => (
					<div key={product.id} className="rewards-info-box display-flex justify-center vertical-align-between wrap">
						<div className="wdh">
							<img src={this.state.image_url+product.image} className="reward-info-pic" alt="reward" />
							<h2 className="info-text-1">{product.name}</h2>
						</div>	
						{/* <div className="reward-offer-box wdh">
							{product.id == 6? <img src={discount32} className="wdh" alt="offers" /> : <img src={discount36} className="wdh" alt="offers" />}
							<h2 className="countdown-text1"><Countdown date={'2020-09-04T00:00:00'} renderer={renderer} /></h2>
						</div> */}
						<div className="wdh">
							<p className="info-text-2 text-max-length">{product.description}</p>
							<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><p className="tooltop-text">{product.description}</p></Tooltip>}>
								<button className="info-text-2 tooltip-anchor" style={{backgroundColor: 'transparent',border: 'none',color: '#F1644A',opacity: '1'}}>Read More</button>
							</OverlayTrigger>
							<div className="reward-quantity-overlay">
								<div className="reward-quantity-line-box"><div className="reward-quantity-line-sub"></div></div>
								<p className="reward-quantity-text align-right">Only {product.quantity} left!</p>
							</div>
							<Button className={"buy-anchor display-flex vertical-align-center justify-center reward-buy-anchor " + (product.quantity == 0? "active":"")} variant="primary" 
							onClick={() => this.confirmation(product)} >
							
								<img src={(product.currency == "GoldCoins"? coins:diamonds)} className={(product.currency == "GoldCoins"? "coins-svg-1":"diamonds-svg")} alt={(product.currency == "GoldCoins"? "coins-svg":"diamonds-svg")} />
								<span className="buy-anchor-span">{product.reward_value}</span>
							</Button>
							<center><span className={"out-of-stock " + (product.quantity == 0? "active":"")} >OUT OF STOCK</span></center>
						</div>
					</div>
				))}
			</div>
			
			<Modal className="modal-pop-up" show={this.state.show} onHide={this.handleClose} centered>
				{/* <Modal.Header closeButton></Modal.Header> */}
				<img src={this.state.image_url+this.state.reward_image} className="modal-pic" alt="img" />
				<h2 className="modal-title">{this.state.reward_name}</h2>
				<p className="modal-text">{this.state.reward_description}</p>
				<Button className="buy-anchor" variant="primary" onClick={() => this.rewardPurchase(this.state.reward_info)}>
					Confirm
				</Button>
			</Modal>
			
			<Modal className="modal-pop-up" show={this.state.insufficient_show} onHide={this.handleClose} centered>
				<img src={require('../images/insufficient-balance.png')} className="modal-pic" alt="img" />
				<h2 className="modal-title">Insufficient Balance</h2>
				<p className="modal-text">Oh ho! You have insufficient balance to purchase</p>
				<Button className="buy-anchor" variant="primary" onClick={this.handleClose}>
					Okay
				</Button>
			</Modal>
			
		</div>
		
    );
  }
}

export default Rewards;
