import React, { Component } from "react";
import '../style/App.sass';
import '../components/Rewards.sass';

import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';

import { ReactComponent as Rectangle1 } from "../images/rectangle-1.svg";
import { ReactComponent as Rectangle2 } from "../images/rectangle-2.svg";
import rectangle3 from '../images/rectangle-3.svg';
import backArrow from '../images/back-arrow.svg';
import coins from '../images/coins.svg';
import diamonds from '../images/daimonds.svg';
import share from '../images/share.png';
import close from '../images/close.svg';
import arrow1 from '../images/arrow-1.svg';
import boardImg from '../images/board-img.png';

import achievementIcon1 from '../images/achievements/1.svg';
import achievementIcon2 from '../images/achievements/2.svg';
import achievementIcon3 from '../images/achievements/3.svg';
import achievementIcon4 from '../images/achievements/4.svg';
import achievementIcon5 from '../images/achievements/5.svg';
import achievementIcon6 from '../images/achievements/6.svg';
import achievementIcon7 from '../images/achievements/7.svg';
import achievementIcon8 from '../images/achievements/8.svg';
import achievementIcon9 from '../images/achievements/9.svg';
import achievementIcon10 from '../images/achievements/10.svg';
import achievementIcon11 from '../images/achievements/11.svg';
import achievementIcon12 from '../images/achievements/12.svg';
import achievementIcon13 from '../images/achievements/13.svg';
import achievementIcon14 from '../images/achievements/14.svg';
import achievementIcon15 from '../images/achievements/15.svg';
import achievementIcon16 from '../images/achievements/16.svg';
import achievementIcon17 from '../images/achievements/17.svg';
import achievementIcon18 from '../images/achievements/18.svg';
import achievementIcon19 from '../images/achievements/19.svg';
import achievementIcon20 from '../images/achievements/20.svg';
import achievementIcon21 from '../images/achievements/21.svg';
import achievementIcon22 from '../images/achievements/22.svg';
import achievementIcon23 from '../images/achievements/23.svg';
import achievementIcon24 from '../images/achievements/24.svg';
import achievementIcon25 from '../images/achievements/25.svg';
import achievementIcon26 from '../images/achievements/26.svg';
import achievementIcon27 from '../images/achievements/27.svg';
import achievementIcon28 from '../images/achievements/28.svg';
import achievementIcon29 from '../images/achievements/29.svg';
import achievementIcon30 from '../images/achievements/30.svg';
import achievementIcon31 from '../images/achievements/31.svg';
import achievementIcon32 from '../images/achievements/32.svg';
import achievementIcon33 from '../images/achievements/33.svg';
import achievementIcon34 from '../images/achievements/34.svg';
import achievementIcon35 from '../images/achievements/35.svg';
import achievementIcon36 from '../images/achievements/36.svg';
import achievementIcon37 from '../images/achievements/37.svg';
import achievementIcon38 from '../images/achievements/38.svg';
import achievementIcon39 from '../images/achievements/39.svg';
import achievementIcon40 from '../images/achievements/40.svg';


class AchievementsPage extends Component {
	
	state = {
		loading: true,
	};
	
	/* modal popup code */
	constructor(props, context) {
		super(props, context);

		//this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);

		this.state = {
			show: false,
			locked: false,
			achievement_id: [],
			
			list: [
				{
					id: 1, image: achievementIcon1, title: '1st Online Win', description: 'Your 1st win against a chess.com player', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 2, image: achievementIcon2, title: 'The Winning Warrior', description: 'Your 25th win in any online game', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 3, image: achievementIcon3, title: 'The Chess Master', description: 'Your 100th win in any online game', active: '', coins : 500 , diamonds : 25
				},
				{
					id: 4, image: achievementIcon4, title: 'All Out War', description: 'Your 25th win against a single player', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 5, image: achievementIcon5, title: 'Freaky Fifty', description: 'You played with 50 different online players', active: '', coins : 0 , diamonds : 50
				},
				{
					id: 6, image: achievementIcon6, title: 'Winner On-Board', description: 'Your 1st win in a board vs board online game', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 7, image: achievementIcon7, title: 'Power Potion', description: 'You used boosters in a winning game', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 8, image: achievementIcon8, title: 'Quick-mate!', description: 'You won an online game within 3 mins using checkmate', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 9, image: achievementIcon9, title: 'Super 20', description: 'You won an online game within 20 moves using checkmate', active: '', coins : 500 , diamonds : 25
				},
				{
					id: 10, image: achievementIcon10, title: 'Killer Queen', description: 'You won an online game with checkmate using a queen', active: '', coins : 200 , diamonds : 10
				},
				{
					id: 11, image: achievementIcon11, title: 'The Unshakeable', description: 'You won an online game without losing any piece using checkmate', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 12, image: achievementIcon12, title: 'Last Man Standing', description: 'You won an online game with more than 100 moves using checkmate', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 13, image: achievementIcon13, title: '1st AI win', description: 'Your 1st win with AI', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 14, image: achievementIcon14, title: 'The Invincible', description: 'You won all the levels with AI', active: '', coins : 500 , diamonds : 25
				},
				{
					id: 15, image: achievementIcon15, title: 'Fame Fifteen', description: 'You won all the levels above 15 with AI', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 16, image: achievementIcon16, title: 'Boss Killer', description: 'Your 1st win with AI level 20', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 17, image: achievementIcon17, title: 'Level-packer', description: 'You played with adaptive AI and made it reach 15+ level', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 18, image: achievementIcon18, title: 'Super-Timer', description: 'You won all the time setting games with AI', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 19, image: achievementIcon19, title: 'Quarter Master', description: 'Your 25th win with AI', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 20, image: achievementIcon20, title: 'Centurys Champion', description: 'Your 100th win with AI', active: '', coins : 500 , diamonds : 25
				},
				{
					id: 21, image: achievementIcon21, title: 'The First Experiment', description: 'Your 1st analysis of online game', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 22, image: achievementIcon22, title: 'First Recall', description: 'Your 1st analysis of history game', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 23, image: achievementIcon23, title: 'Live Scrutiny', description: 'Your 1st analysis of live stream game', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 24, image: achievementIcon24, title: 'Quarterly Report', description: 'Your 25th analysis of any game', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 25, image: achievementIcon25, title: 'Ace Veteran', description: 'Your 100th analysis of any game', active: '', coins : 500 , diamonds : 25
				},
				{
					id: 26, image: achievementIcon26, title: 'Chesscast', description: '', active: 'You finished a full live stream', coins : 200 , diamonds : 0
				},
				{
					id: 27, image: achievementIcon27, title: 'Quarter Pillar', description: 'Your 25th full live stream', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 28, image: achievementIcon28, title: 'Blockbuster', description: 'Your 100th full live stream', active: '', coins : 500 , diamonds : 25
				},
				{
					id: 29, image: achievementIcon29, title: 'First Broadcast', description: 'Your first full live stream on the board', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 30, image: achievementIcon30, title: 'First Byte', description: 'Your first conversation on chat', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 31, image: achievementIcon31, title: 'Hi- (twenty)Five', description: 'You made a conversation with 25 people', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 32, image: achievementIcon32, title: 'Check-meet', description: 'Your 1st video call in an online game', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 33, image: achievementIcon33, title: 'Friendly First', description: 'The player has added a friend for the first time to their list', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 34, image: achievementIcon34, title: 'Happy 25th!', description: 'The player has added atleast 25 friends to their list', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 35, image: achievementIcon35, title: 'Three Spree', description: 'You played atleast one game for 3 consecutive days', active: '', coins : 200 , diamonds : 0
				},
				{
					id: 36, image: achievementIcon36, title: 'Dauntless Warrior', description: 'You played atleast one game for 7 consecutive days', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 37, image: achievementIcon37, title: 'On Fire', description: 'You played atleast one game for 15 consecutive days', active: '', coins : 500 , diamonds : 25
				},
				{
					id: 38, image: achievementIcon38, title: 'Super Splendid', description: 'You won 10 games in a row with AI', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 39, image: achievementIcon39, title: 'Fierce Five', description: 'You won 5 online games in a row with a single player', active: '', coins : 250 , diamonds : 10
				},
				{
					id: 40, image: achievementIcon40, title: 'Terrific Three', description: 'You won 3 games in a row with AI level 20', active: '', coins : 500 , diamonds : 25
				}
			]
		};
	}
	
	
	/* modal popup code */
	
	
	async componentDidMount() {
		
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const email = params.get('email');
		const player_id = params.get('player_id');
	
		const apiUrl = process.env.REACT_APP_URL;
		const url = apiUrl+"/achievement/v1/achievementcollection/"+player_id;
		const response = await fetch(url);
		const data = await response.json();
		
		localStorage.setItem('player_id', player_id);
	
		const achievement = data.items;
		
		const achievement_list = this.state.list;
		
		if(achievement){
			for (var x = 0; x < achievement_list.length; x++) {
				for (var j = 0; j < achievement.length; j++) {
					if(achievement[j]['id'] == achievement_list[x]['id'])
					{
						this.state.achievement_id.push(achievement[j]['id']);
						achievement_list[x]['active'] = 'active';
						achievement_list[x]['coins'] = achievement[j]['coins'];
						achievement_list[x]['diamonds'] = achievement[j]['diamonds'];
					}
				}
			}
		}
		
		this.setState({'list':achievement_list});
	
		this.setState({'achievement':achievement});
		
	}

	
	
	confirmation(achievement_info) {
		
		this.setState({	achievement_info : achievement_info , achievement_title: achievement_info.title ,achievement_image : achievement_info.image , achievement_description : achievement_info.description, achievement_coins : achievement_info.coins, achievement_diamonds :achievement_info.diamonds});
		
		if(this.state.achievement_id.includes(achievement_info.id))
		{
			this.setState({ show: true });
			this.setState({ locked: false });
		}
		else
		{
			this.setState({ show: false });
			this.setState({ locked: true });
		}

	}
	
	
	
	handleClose() {
		this.setState({ show: false, locked: false });
	}

	
	appBack() {
		window.location.href="/back";
	}
	
  render() {
	
	const{ addClass } = this.state;	
	  
    return (
		<div className="container-overlay achievements-page">
		
			<Rectangle1 className="rectangle-1" />
			<Rectangle2 className="rectangle-2" />
			<img src={rectangle3} className="rectangle-3" alt="rectangle" />
			
			<header className="header display-flex justify-center vertical-align-center posr">
				<div className="back-box">
					<img src={backArrow} className="back-arrow" alt="back-arrow" onClick={() => this.appBack()} />
				</div>
				<span className="title-text">ACHIEVEMENTS</span>		
			</header>
			
			{/* <div className="achievements-explore-box display-flex">
				<div className="achievements-explore-info-box">
					<h2 className="achievements-explore-title align-left">Protect your board</h2>
					<p className="achievements-explore-text align-left">Achieve safety with our sleeves for your boards!</p>
					<a href="javasript:void(0);" className="achievements-explore-anchor">Explore Now <img src={arrow1} className="arrow1-img" alt="" /></a>
				</div>
				<div className="achievements-explore-img-box">
					<img src={boardImg} className="achievements-board-img wdh" alt="" />
				</div>
			</div> */}
			
			<div className="achievements-overlay pad-10 posr display-flex justify-around wrap">
				{ 
					this.state.list.map((achievement, i) => (
						<div key={achievement.id} className={"rewards-info-box display-flex justify-center vertical-align-between wrap posr " + achievement.active} onClick={() => this.confirmation(achievement)}>
							<div className="wdh">
								<img src={achievement.image} className="reward-info-pic" alt="achievement img" />
								<h2 className="info-text-1">{achievement.title}</h2>
							</div>
						</div>
					))
				}
			</div>
			
		
			<Modal className="modal-pop-up achievements-popup" show={this.state.show} onHide={this.handleClose} centered>
				{/* <img src={close} className="close-icon" alt="close icon" onClick={this.handleClose} /> */}
				<img src={this.state.achievement_image} className="modal-pic" alt="img" />
				<h2 className="modal-title clr-white">{this.state.achievement_title}</h2>
				<p className="modal-text clr-white">{this.state.achievement_description}</p>
				<div className="modal-sub-box display-flex justify-center vertical-align-center">
					<div className="header-coin-box display-flex vertical-align-center">
						<img src={coins} className="coins-svg" alt="coins" />
						<span className="reward-text1">{this.state.achievement_coins}</span>
					</div>
					<div className="header-diamond-box display-flex vertical-align-center">
						<img src={diamonds} className="diamonds-svg" alt="diamonds" />
						<span className="reward-text1">{this.state.achievement_diamonds}</span>
					</div>	
				</div>
				<Button className="buy-anchor" variant="primary" onClick={this.handleClose}>
					Close
				</Button>
			</Modal>
			
			
			<Modal className="modal-pop-up locked-achievements-popup" show={this.state.locked} onHide={this.handleClose} centered>
				{/* <img src={close} className="close-icon" alt="close icon" onClick={this.handleClose} /> */}
				<img src={this.state.achievement_image} className="modal-pic" alt="img" />
				<h2 className="modal-title clr-white">{this.state.achievement_title} <span className="clr-pink">(Locked)</span></h2>
				<p className="modal-text clr-white">{this.state.achievement_description}</p>
				<div className="modal-sub-box display-flex justify-center vertical-align-center">
					<div className="header-coin-box display-flex vertical-align-center">
						<img src={coins} className="coins-svg" alt="coins" />
						<span className="reward-text1">{this.state.achievement_coins}</span>
					</div>
					<div className="header-diamond-box display-flex vertical-align-center">
						<img src={diamonds} className="diamonds-svg" alt="diamonds" />
						<span className="reward-text1">{this.state.achievement_diamonds}</span>
					</div>	
				</div>
				<Button className="buy-anchor" variant="primary" onClick={this.handleClose}>
					Close
				</Button>
			</Modal>
			
		</div>
    );
  }
}

export default AchievementsPage;
