import React, { Component } from "react";
import './CheckoutForm.sass';

import Lottie from 'react-lottie';
import animationData from '../lotties/done.json';
import animationData1 from '../lotties/confetti.json';

import { Form, Button, Modal } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';
	

	// RegEx for phone number validation
	const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
	
	// Schema for yup
	const validationSchema = Yup.object().shape({
		firstname: Yup.string()
			.min(2, "*Names must have at least 2 characters")
			.max(100, "*Names can't be longer than 100 characters")
			.required("*First Name is required"),
		lastname: Yup.string()
			.min(2, "*Names must have at least 2 characters")
			.max(100, "*Names can't be longer than 100 characters")
			.required("*Last Name is required"),	
		phone: Yup.string()
			.matches(phoneRegExp, "*Phone number is not valid")
			.required("*Phone number required"),
		email: Yup.string()
			.email("*Must be a valid email address")
			.max(100, "*Email must be less than 100 characters")
			.required("*Email is required"),
		address1: Yup.string()
			.max(50, "*Address1 must be less than 50 characters")
			.required("*Address1 required"),
		zipcode: Yup.string()
			.max(10, "*Zipcode must be less than 10 characters")
			.required("*Zipcode required"),
		state: Yup.string()
			.required("*State required"),
		city: Yup.string()
			.required("*City required")	
	});


class CheckoutForm extends Component {
	
	state = {
		loading: true,
		reward: null,
		country: 'US',
		popupshow : false
	};
	
	componentDidMount() {
		var rewardData = JSON.parse(localStorage.getItem('reward'));
		
		this.setState({ image_url: rewardData.image_url, reward_image: rewardData.image, reward_name : rewardData.name , reward_value : rewardData.reward_value  });
	}
	
	constructor(props, context) {
		super(props, context);
		this.onSelectFlag = this.onSelectFlag.bind(this);

	}
		
	onSelectFlag(countryCode){

		this.setState({
			 country: countryCode
		});
	}
	
	handleClose() {
		this.setState({ popupshow: false});
	}
	
  render() {
	
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
    };
	
	const defaultOptions1 = {
		loop: false,
		autoplay: true,
		animationData: animationData1,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
    };
	  
    return (
		
		<div className="checkout-form-box">
			
			{/* Sets initial values for form inputs */}
			<Formik 
			
			initialValues={{ reward_id : localStorage.getItem('reward_id') , player_id : localStorage.getItem('player_id') , country : this.state.country , firstname:"", lastname:"", phone:"", email:localStorage.getItem('email'), address1:"", address2:"", zipcode:"", state:"", city:""}} 
			// Hooks up our validationSchema to Formik 
			validationSchema={validationSchema}
			onSubmit={(values, {setSubmitting, resetForm}) => {
				// enableReinitialize
				// When button submits form and form is in the process of submitting, submit button is disabled
				setSubmitting(true);
	
				// Simulate submitting to database, shows us values submitted, resets form
				setTimeout(() => {
					
					values.country = this.state.country;
					
					//alert(JSON.stringify(values, null, 2));
					const apiUrl  = process.env.REACT_APP_API_URL;
					fetch(apiUrl+'/rewards/deduct', {
					  method: 'POST',
					  // headers: {
						// 'Accept': 'application/json',
						// 'Content-Type': 'application/json',
					  // },
					  body: JSON.stringify(
						values
					  )
					})
					.then(response => response.json()).then(response => {
						if(response.status == 1){
							this.setState({
								 popupshow: true
							});
						}else{
							alert('Already purchased this reward');
						}
					})
					.catch(error =>{
						console.log(error);
					});
					
					// Resets form after submission is complete
					// resetForm();
					// Sets setSubmitting to false after form is reset
					setSubmitting(false);
				}, 500);
			}}	
			>
				{/* Callback function containing Formik state and helpers that handle common form actions */}
				{( {values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting }) => (
					<form onSubmit={handleSubmit} className="checkout-form">
						{console.log(values)}
						<Form.Group controlId="formId">
							<Form.Control
								type="hidden"
								name="reward_id"
								value={values.reward_id}
							/>
						</Form.Group>
						<Form.Group controlId="formPlayerId">
							<Form.Control
								type="hidden"
								name="player_id"
								value={values.player_id}
							/>
						</Form.Group>
						<Form.Group controlId="formCountry">
							<Form.Control
								type="hidden"
								name="country"
								value={values.country}
							/>
						</Form.Group>
						<Form.Group controlId="formFirstName">
							<Form.Control
								type="text"
								/* This name property is used to access the value of the form element via values.nameOfElement */
								name="firstname"
								placeholder="First Name"
								/* Set onChange to handleChange */
								onChange={handleChange}
								/* Set onBlur to handleBlur */
								onBlur={handleBlur}
								/* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
								value={values.firstname}
								/* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
								className={touched.firstname && errors.firstname ? "error" : null}
							/>
							{/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
							{touched.firstname && errors.firstname ? (
								<div className="error-message">{errors.firstname}</div>
							): null}
						</Form.Group>
						<Form.Group controlId="formLastName">
							<Form.Control
								type="text"
								name="lastname"
								placeholder="Last Name"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.lastname}
								className={touched.lastname && errors.lastname ? "error" : null}
							/>
							{touched.lastname && errors.lastname ? (
								<div className="error-message">{errors.lastname}</div>
							): null}
						</Form.Group>
						<Form.Group className="form-phone-inpt" controlId="formPhone">
							<ReactFlagsSelect defaultCountry="US" showSelectedLabel={false} searchable={true}  onSelect={this.onSelectFlag} />
							<Form.Control
								type="text"
								name="phone"
								placeholder="(201)555-0123"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.phone}
								className={touched.phone && errors.phone ? "error" : null}
							/>
							{touched.phone && errors.phone ? (
								<div className="error-message">{errors.phone}</div>
							): null}
						</Form.Group>
						<Form.Group controlId="formEmail">
							<Form.Control
								type="email"
								name="email"
								placeholder="Email address"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
								className={touched.email && errors.email && touched.email == null ? "error" : null}
							/>
							{touched.email && errors.email && touched.email == null ? (
								<div className="error-message">{errors.email}</div>
							): null}
						</Form.Group>
						<Form.Group controlId="formAddress1">
							<Form.Control
								type="text"
								name="address1"
								placeholder="Address Line 1"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.address1}
								className={touched.address1 && errors.address1 ? "error" : null}
							/>
							{touched.address1 && errors.address1 ? (
								<div className="error-message">{errors.address1}</div>
							): null}
						</Form.Group>
						<Form.Group controlId="formAddress2">
							<Form.Control
								type="text"
								name="address2"
								placeholder="Address Line 2"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.address2}
								className={touched.address2 && errors.address2 ? "error" : null}
							/>
							{touched.address2 && errors.address2 ? (
								<div className="error-message">{errors.address2}</div>
							): null}
						</Form.Group>
						<Form.Group controlId="formZipcode">
							<Form.Control
								type="text"
								name="zipcode"
								placeholder="Zip/Postal Code"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.zipcode}
								className={touched.zipcode && errors.zipcode ? "error" : null}
							/>
							{touched.zipcode && errors.zipcode ? (
								<div className="error-message">{errors.zipcode}</div>
							): null}
						</Form.Group>
						<Form.Group controlId="formState">
							<Form.Control
								type="text"
								name="state"
								placeholder="State"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.state}
								className={touched.state && errors.state ? "error" : null}
							/>
							{touched.state && errors.state ? (
								<div className="error-message">{errors.state}</div>
							): null}
						</Form.Group>
						<Form.Group controlId="formCity">
							<Form.Control
								type="text"
								name="city"
								placeholder="City"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.City}
								className={touched.city && errors.city ? "error" : null}
							/>
							{touched.city && errors.city ? (
								<div className="error-message">{errors.city}</div>
							): null}
						</Form.Group>
						<Button className="buy-anchor" variant="primary" type="submit" disabled={isSubmitting}>
							Get The Reward
						</Button>
					</form>
				)}
			</Formik>
			
			<Modal className="modal-pop-up" show={this.state.popupshow}  centered>
				{/* <Modal.Header closeButton></Modal.Header> */}
					{/* <img src={this.state.image_url + this.state.reward_image} className="modal-pic" alt="img" /> */}
				<div className="lottie-box"><Lottie options={defaultOptions} height={150} width={150} /></div>
				<div className="lottie-box-1"><Lottie options={defaultOptions1} /></div>
				<h2 className="modal-title">Congratulations!</h2>
				<p className="modal-text">You have redeemed your coins for <br /> <span className="bold">{this.state.reward_name}</span></p>
				<Button className="buy-anchor" variant="primary" onClick={() => { window.location.href="/?player_id="+localStorage.getItem('player_id')+'&email='+localStorage.getItem('email') }}>
					Done
				</Button>
			</Modal>
				
			
		</div>
		
		
			
		
    );
  }
}

export default CheckoutForm;
