import React, { Component } from "react";
import './Boards.sass';

import { Link } from "react-router-dom";

import gksBag from '../images/accessories/gks-bag.jpg';
import proBag from '../images/accessories/pro-bag.jpg';
import swapBag from '../images/accessories/swap-bag.jpg';
import neoBag from '../images/accessories/neo-bag.jpg';
import checkersPieces from '../images/accessories/checkers-pieces.jpg';
import chessBox from '../images/accessories/chess-box.jpg';
import woodenPieces from '../images/accessories/wooden-pieces.jpg';
import plasticPieces from '../images/accessories/plastic-pieces.jpg';
import charger1 from '../images/accessories/charger-1.jpg';
import charger2 from '../images/accessories/charger-2.jpg';


class Accessories extends Component {
  render() {
    return (
		<div className="boards-overlay display-flex wrap pad-10 posr">
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={gksBag} className="board-img" alt="gks board bag" />
					<div className="padding-overlay">
						<div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div>	
						<div className="board-title-box">
							<h2 className="board-title align-left">Protective Bag</h2>
							<p className="board-features-text align-left">Carry your board safely in this premium case, against any external scratches.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$39 <span className="board-features-text align-left"><strike>$70</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=ChessboardSleeve-GrandKingdomSet&parent_product=GrandKingdomSet&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=ChessboardSleeve-GrandKingdomSet" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={proBag} className="board-img" alt="pro board bag" />
					<div className="padding-overlay">
						{/* <div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div> */}	
						<div className="board-title-box">
							<h2 className="board-title align-left">Protective Bag</h2>
							<p className="board-features-text align-left">Carry your board safely in this premium case, against any external scratches.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$39 <span className="board-features-text align-left"><strike>$70</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=ChessboardSleeve-PRO&parent_product=SquareOffPRO&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=ChessboardSleeve-PRO" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={swapBag} className="board-img" alt="swap board bag" />
					<div className="padding-overlay">
						{/* <div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div> */}	
						<div className="board-title-box">
							<h2 className="board-title align-left">Protective Bag</h2>
							<p className="board-features-text align-left">Carry your board safely in this premium case, against any external scratches.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$39 <span className="board-features-text align-left"><strike>$70</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=ChessboardSleeve-SWAP&parent_product=SWAP&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=ChessboardSleeve-SWAP" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={neoBag} className="board-img" alt="neo board bag" />
					<div className="padding-overlay">
						{/* <div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div> */}	
						<div className="board-title-box">
							<h2 className="board-title align-left">Protective Bag</h2>
							<p className="board-features-text align-left">Carry your board safely in this premium case, against any external scratches.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$39 <span className="board-features-text align-left"><strike>$70</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=ChessboardSleeve-Neo&parent_product=Neo&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=ChessboardSleeve-Neo" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={checkersPieces} className="board-img" alt="checkersPieces" />
					<div className="padding-overlay">
						{/* <div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div> */}	
						<div className="board-title-box">
							<h2 className="board-title align-left">All-in-one pieces</h2>
							<p className="board-features-text align-left">Not just chess, play multiple games on your SWAP board with these add-on pieces that are compatible with Checkers (24 pieces), Connect4 (64 pieces) & Halma (32 pieces)</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$29 <span className="board-features-text align-left"><strike>$70</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=CheckersPieces&parent_product=SWAP&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=ChessboardSleeve-CheckersPieces" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={chessBox} className="board-img" alt="chessBox" />
					<div className="padding-overlay">
						{/* <div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div> */}	
						<div className="board-title-box">
							<h2 className="board-title align-left">Chess Piece Storage Box</h2>
							<p className="board-features-text align-left">Now keep your chess pieces intact in this classy & compact case.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$39 <span className="board-features-text align-left"><strike>$70</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=CheessPieceStorageBox-GrandKingdomSet,Neo&SWAP&parent_product=GrandKingdomSet&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=CheessPieceStorageBox-GrandKingdomSet,Neo&SWAP" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={woodenPieces} className="board-img" alt="woodenPieces" />
					<div className="padding-overlay">
						{/* <div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div> */}	
						<div className="board-title-box">
							<h2 className="board-title align-left">Wooden Chess Piece</h2>
							<p className="board-features-text align-left">Intricately carved wooden chess pieces for a classic playing experience.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$39 <span className="board-features-text align-left"><strike>$79</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=WoodenChessPiece-Neo&SWAP&parent_product=Neo&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=WoodenChessPiece-Neo&SWAP" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={charger2} className="board-img" alt="charger2" />
					<div className="padding-overlay">
						{/* <div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div> */}	
						<div className="board-title-box">
							<h2 className="board-title align-left">Power Adapter - GKS & KDS</h2>
							<p className="board-features-text align-left">Keep your chessboard game ready always, with this power adaptor.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$29 <span className="board-features-text align-left"><strike>$39</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=PowerAdapter-GrandKingdomSet&KingdomSet&parent_product=GrandKingdomSet&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=PowerAdapter-GrandKingdomSet&KingdomSet" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={plasticPieces} className="board-img" alt="plasticPieces" />
					<div className="padding-overlay">
						{/* <div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div> */}	
						<div className="board-title-box">
							<h2 className="board-title align-left">Plastic Chess Piece</h2>
							<p className="board-features-text align-left">Premium plastic chess set for a fine playing experience.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$29 <span className="board-features-text align-left"><strike>$39</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=PlasticChessPiece-Neo&Swap&parent_product=Neo&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=PlasticChessPiece-Neo&Swap" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={charger1} className="board-img" alt="charger1" />
					<div className="padding-overlay">
						{/* <div className="limited-stock-box display-flex vertical-align-center justify-between">
							<div className="limited-left">
								<span className="product-tags">Bestseller</span>
							</div>		
						</div> */}	
						<div className="board-title-box">
							<h2 className="board-title align-left">Power Adapter - SWAP & NEO</h2>
							<p className="board-features-text align-left">Keep your chessboard game ready always, with this power adaptor.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$29 <span className="board-features-text align-left"><strike>$39</strike></span></h2>
						<a href="https://squareoffnow.com/accessories?product_name=PowerAdapter-NEO&SWAP&parent_product=Neo&utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=PowerAdapter-NEO&SWAP" className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
		</div>
    );
  }
}

export default Accessories;
