import React from "react";
import { Route, Switch } from "react-router-dom";

import StorePage from "../pages/StorePage";
import CheckoutPage from "../pages/CheckoutPage";
import AchievementsPage from "../pages/AchievementsPage";

export default function PublicRoutes() {
  
  return (
    <Switch>
      <Route exact path="/" component={StorePage} />
      <Route path="/checkout" component={CheckoutPage} />
      <Route path="/achievements" component={AchievementsPage} />
    </Switch>
  );
}
