import React, { Component } from "react";
import '../style/App.sass';

import { Link } from "react-router-dom";

import { ReactComponent as Rectangle1 } from "../images/rectangle-1.svg";
import { ReactComponent as Rectangle2 } from "../images/rectangle-2.svg";
import rectangle3 from '../images/rectangle-3.svg';
import backArrow from '../images/back-arrow.svg';
import coins from '../images/coins.svg';
import diamonds from '../images/daimonds.svg';

import CheckoutForm from "../components/CheckoutForm";


class CheckoutPage extends Component {
	state = {
		loading: true,
		reward: null
	};
	
	componentDidMount() {
		var rewardData = JSON.parse(localStorage.getItem('reward'));
		
		this.setState({ image_url: rewardData.image_url, reward_image: rewardData.image, reward_name : rewardData.name , reward_value : rewardData.reward_value , currency: rewardData.currency  });
	}
  
  
  
  render() {
    return (
		<div className="container-overlay checkout-page">
		
			<Rectangle1 className="rectangle-1" />
			<Rectangle2 className="rectangle-2" />
			<img src={rectangle3} className="rectangle-3" alt="rectangle" />
			
			<header className="header display-flex justify-center vertical-align-center posr">
				<div className="back-box">
					<Link to="/">
						<img src={backArrow} className="back-arrow" alt="back-arrow" />
					</Link>
				</div>
				<span className="title-text">CHECKOUT</span>		
			</header>
			
			<div className="checkout-overlay pad-10 posr">
				<div className="checkout-product-info-box display-flex justify-between vertical-align-center">
					<div className="checkout-product-info-sub display-flex justify-left vertical-align-center">
						<img src={this.state.image_url + this.state.reward_image} className="checkout-product-pic" alt="reward image" />
						<span className="checkout-product-text align-left">{this.state.reward_name}</span>
					</div>
					<div className="checkout-product-info-sub display-flex justify-right vertical-align-center">
						
						<img src={(this.state.currency == "GoldCoins"? coins:diamonds)} className={(this.state.currency == "GoldCoins"? "coins-svg-1":"diamonds-svg")} alt={(this.state.currency == "GoldCoins"? "coins-svg":"diamonds-svg")} />
						<span className="buy-anchor-span clr-black align-right">{this.state.reward_value} {(this.state.currency == "GoldCoins"? "Coins":"Diamonds")}</span>
					</div>
				</div>
				
				<div className="checkout-form-overlay">
					<h2 className="checkout-form-title align-left">Shipping Details</h2>
					<p className="only-us align-left">Physical rewards are only valid for US and Europe</p>
					<CheckoutForm />
				</div>
			</div>
			
		</div>
    );
  }
}

export default CheckoutPage;
