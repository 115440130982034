import React, { Component } from "react";
import '../style/App.sass';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import backArrow from '../images/back-arrow.svg';
import coins from '../images/coins.svg';
import diamonds from '../images/daimonds.svg';
import rectangle1 from '../images/rectangle-1.svg';
import rectangle2 from '../images/rectangle-2.svg';
import rectangle3 from '../images/rectangle-3.svg';
import notify from '../images/notify.svg';

/* import { ReactComponent as Rectangle1 } from "../images/rectangle-1.svg";
import { ReactComponent as Rectangle2 } from "../images/rectangle-2.svg"; */

import Rewards from "../components/Rewards";
import Diamonds from "../components/Diamonds";
import Boards from "../components/Boards";
import Accessories from "../components/Accessories";


class StorePage extends Component {
	state = {
		loading: true,
	};
	
	async componentDidMount() {
		
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const email = params.get('email');
		const player_id = params.get('player_id');
		
		this.setState({ coins: localStorage.getItem('coins'), diamonds: localStorage.getItem('diamonds'), loading: false});
		
		if(player_id != null) {
			const apiUrl = process.env.REACT_APP_API_URL;
			const url = apiUrl+"/reward/"+player_id+"/"+email;
			const response = await fetch(url);
			const data = await response.json();
			
			localStorage.setItem('diamonds', data.diamonds);
			localStorage.setItem('coins', data.coins);
			localStorage.setItem('player_id', player_id);
			localStorage.setItem('email', email);
			
			
			this.setState({ coins: data.coins, diamonds: data.diamonds, loading: false});
		}
		
	}
	
	
	
	appBack() {
		window.location.href="/back";
	}
	
	
	
  render() {
    return (
		<div className="container-overlay">
		
			{/* <Rectangle1 className="rectangle-1" />
			<Rectangle2 className="rectangle-2" /> */}
			<img src={rectangle1} className="rectangle-1" alt="rectangle" />
			<img src={rectangle2} className="rectangle-2" alt="rectangle" />
			<img src={rectangle3} className="rectangle-3" alt="rectangle" />
			
			<header className="header display-flex justify-between vertical-align-center">
				<div className="back-box">
					<img src={backArrow} className="back-arrow" alt="back-arrow" onClick={() => this.appBack()} />
				</div>
				<span className="title-text">STORE</span>	
				<div className="header-sub-box display-flex justify-between vertical-align-center">
					<div className="header-coin-box display-flex vertical-align-center">
						<img src={coins} className="coins-svg" alt="coins" />
						<span className="reward-text1">{this.state.coins}</span>
					</div>
					<div className="header-diamond-box display-flex vertical-align-center">
						<img src={diamonds} className="diamonds-svg" alt="diamonds" />
						<span className="reward-text1">{this.state.diamonds}</span>
					</div>	
				</div>	
			</header>
			
			<div className="tab-overlay">
				<Tabs defaultActiveKey="boards" id="uncontrolled-tab-example">
					<Tab eventKey="boards" title="Boards">
						<Boards />
					</Tab>
					<Tab eventKey="accessories" title="Accessories">
						<Accessories />
					</Tab>
					<Tab eventKey="rewards" title="Rewards">
						<Rewards />
					</Tab>
					<Tab eventKey="diamonds" title="Diamonds">
						<Diamonds />
					</Tab>
				</Tabs>
			</div>
			
		</div>
    );
  }
}

export default StorePage;
