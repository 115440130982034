import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PublicRoutes } from "./routes";

//GA code
import ReactGA from 'react-ga';
import ReactDOM from "react-dom";
//GA code


//GA code
ReactGA.initialize("UA-65048663-4");
//GA code


const BrowserHistory = createBrowserHistory();

//GA code
BrowserHistory.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});
//GA code

class App extends Component {
	
	//GA code
	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}
	//GA code
	
  render() {
    return (
		<Router history={BrowserHistory}>
			{PublicRoutes()}
		</Router>
    );
  }
}

export default App;
