import React, { Component } from "react";
import './Boards.sass';

import { Link } from "react-router-dom";
import Countdown from "react-countdown";

import ReactGA from 'react-ga';

import gksBoard from '../images/gif-gks.gif';
import proBoard from '../images/gif-pro.gif';
import kdsBoard from '../images/KDS@2x.png';
import neoBoard from '../images/gif-neo.gif';
import swapBoard from '../images/gif-swap.gif';

import liveStreaming from '../images/live-streaming-01.svg';
import autoReset from '../images/auto-reset-01.svg';
import parkingSpace from '../images/parking-space-01.svg';
import automated from '../images/automated-01.svg';
import connected from '../images/globally-connected-01.svg';
import ai from '../images/ai-01.svg';
import victor from '../images/viktor-01.svg';
import light from '../images/light-compact-01.svg';
import chessCom from '../images/chess-integration-01.svg';
import multiGame from '../images/multigame-01.svg';
import magneticSensor from '../images/magnetic-sensor-surface-01.svg';
import blitz from '../images/blitz-01.svg';
import rollable from '../images/rollable.svg';
import tournament from '../images/tournament.svg';
import leds from '../images/leds.svg';




const gksClick = () => {
	ReactGA.event({ category: 'Reward store clicks', action: 'GKS button click' });
};

const proClick = () => {
	ReactGA.event({ category: 'Reward store clicks', action: 'Pro button click' });
};

/* const kdsClick = () => {
	ReactGA.event({ category: 'Reward store clicks', action: 'KDS store click' });
}; */

const swapClick = () => {
	ReactGA.event({ category: 'Reward store clicks', action: 'Swap button click' });
};

const neoClick = () => {
	ReactGA.event({ category: 'Reward store clicks', action: 'Neo button click' });
};




const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span>
        {days} Days {hours}:{minutes}:{seconds}
      </span>
    );
};


class Boards extends Component {
  render() {
    return (
		<div className="boards-overlay display-flex wrap pad-10 posr">
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={gksBoard} className="board-img" alt="gks board" />
					<div className="padding-overlay">
						<div className="limited-stock-box display-flex vertical-align-center justify-between">
							{/* <div className="limited-left">
								<span className="product-tags">Deal Of The Year</span>
							</div> */}
							{/* <div className="limited-right">
								<h2 className="countdown-text"><Countdown date={'2020-11-30T19:00:00'} renderer={renderer} /></h2>
							</div> */}		
						</div>	
						<div className="board-title-box">
							<h2 className="board-title align-left">Grand Kingdom Set</h2>
							<p className="board-features-text align-left">The finest smart chessboard in the world.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="board-features-box display-flex justify-between">
						<div className="board-features-sub">
							<img src={liveStreaming} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Livestream chess matches</h2>
						</div>
						<div className="board-features-sub">
							<img src={autoReset} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Auto reset pieces</h2>
						</div>
						<div className="board-features-sub">
							<img src={parkingSpace} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Designated space for pieces</h2>
						</div>
					</div>
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$449 <span className="board-features-text align-left"><strike>$499</strike></span></h2>
						<a href="https://squareoffnow.com/product/gks?utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=GKS" onClick={gksClick} className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={proBoard} className="board-img" alt="pro board" />
					<div className="padding-overlay">
						<div className="limited-stock-box display-flex vertical-align-center justify-between">
							{/* <div className="limited-left">
								<span className="product-tags">Deal Of The Year</span>
							</div> */}
							{/* <div className="limited-right">
								<h2 className="countdown-text"><Countdown date={'2020-11-30T19:00:00'} renderer={renderer} /></h2>
							</div> */}		
						</div>
						<div className="board-title-box">
							<h2 className="board-title align-left">Square Off PRO</h2>
							<p className="board-features-text align-left">Ultra portable, rollable e-chessboard</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="board-features-box display-flex justify-between">
						<div className="board-features-sub">
							<img src={rollable} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Rollable and Hence Portable</h2>
						</div>
						<div className="board-features-sub">
							<img src={tournament} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Tournament Size</h2>
						</div>
						<div className="board-features-sub">
							<img src={leds} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">LED Lights</h2>
						</div>
					</div>
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$210 <span className="board-features-text align-left"><strike>$279</strike></span></h2>
						<a href="https://squareoffnow.com/pro?utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=Pro" onClick={proClick} className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			{/* <div className="boards-info-box">
				<div className="limited-stock-box display-flex vertical-align-center justify-between">
					<div className="limited-left">
						<span className="product-tags">Xmas Deal</span>
					</div>
					<div className="limited-right">
						<h2 className="countdown-text"><Countdown date={'2020-11-30T19:00:00'} renderer={renderer} /></h2>
					</div>		
				</div>
				<div className="boards-info-sub display-flex">
					<img src={kdsBoard} className="board-img" alt="gks board" />
					<div className="board-title-box">
						<h2 className="board-title align-left">Kingdom Set</h2>
						<p className="board-features-text grediant align-left">Get $20 off + Free Shipping + Board Sleeve</p>
					</div>	
				</div>
				<div className="board-features-box display-flex justify-between">
					<div className="board-features-sub">
						<img src={automated} className="chess-piece-f" alt="feature" />
						<h2 className="board-features-text">Self-moving pieces</h2>
					</div>
					<div className="board-features-sub">
						<img src={connected} className="chess-piece-f" alt="feature" />
						<h2 className="board-features-text">Play with anyone</h2>
					</div>
					<div className="board-features-sub">
						<img src={ai} className="chess-piece-f" alt="feature" />
						<h2 className="board-features-text">No opponent needed</h2>
					</div>
				</div>
				<div className="wdh">
					<a href="https://squareoffnow.com/product/kds" onClick={kdsClick} className="buy-anchor display-flex vertical-align-center justify-center">
						<span className="buy-anchor-span">View Product</span>
					</a>
				</div>
			</div> */}
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={swapBoard} className="board-img" alt="swap board" />
					<div className="padding-overlay">
						<div className="limited-stock-box display-flex vertical-align-center justify-between">
							{/* <div className="limited-left">
								<span className="product-tags">Pre-order Now</span>
							</div> */}
							{/* <div className="limited-right">
								<h2 className="countdown-text"><Countdown date={'2020-09-04T00:00:00'} renderer={renderer} /></h2>
							</div> */}		
						</div>
						<div className="board-title-box">
							<h2 className="board-title align-left">SWAP</h2>
							<p className="board-features-text align-left">The fastest chessboard on the planet.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="board-features-box display-flex justify-between">
						<div className="board-features-sub">
							<img src={multiGame} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Multiple games. One board.</h2>
						</div>
						<div className="board-features-sub">
							<img src={magneticSensor} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Blitz chess</h2>
						</div>
						<div className="board-features-sub">
							<img src={blitz} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Magnetic sensor surface</h2>
						</div>
					</div>
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$349 <span className="board-features-text align-left"><strike>$379</strike></span></h2>
						<a href="https://squareoffnow.com/product/swap?utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=Swap" onClick={swapClick} className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
			<div className="boards-info-box">
				<div className="boards-info-sub display-flex flex-wrap">
					<img src={neoBoard} className="board-img" alt="neo board" />
					<div className="padding-overlay">
						<div className="limited-stock-box display-flex justify-between">
							{/* <div className="limited-left">
								<span className="product-tags">Pre-order Now</span>
							</div> */}
							{/* <div className="limited-right">
								<h2 className="countdown-text"><Countdown date={'2020-09-04T00:00:00'} renderer={renderer} /></h2>
							</div> */}		
						</div>
						<div className="board-title-box">
							<h2 className="board-title align-left">NEOz</h2>
							<p className="board-features-text align-left">A compact yet versa-tile chessboard.</p>
						</div>	
					</div>	
				</div>
				<div className="padding-overlay">
					<div className="board-features-box display-flex justify-between">
						<div className="board-features-sub">
							<img src={light} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Light and Compact</h2>
						</div>
						<div className="board-features-sub">
							<img src={chessCom} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Chess.com integration</h2>
						</div>
						<div className="board-features-sub">
							<img src={blitz} className="chess-piece-f" alt="feature" />
							<h2 className="board-features-text">Magnetic sensor surface</h2>
						</div>
					</div>
					<div className="explore-box wdh display-flex vertical-align-center justify-between">
						<h2 className="board-title align-left">$299 <span className="board-features-text align-left"><strike>$319</strike></span></h2>
						<a href="https://squareoffnow.com/product/neo?utm_source=SQDB&utm_medium=App&utm_campaign=Shop&utm_term=Neo" onClick={neoClick} className="buy-anchor display-flex vertical-align-center justify-center">
							<span className="buy-anchor-span">Explore</span>
						</a>
					</div>
				</div>
			</div>
			
		</div>
    );
  }
}

export default Boards;
