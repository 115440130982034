import React, { Component } from "react";
import './Diamonds.sass';

import { Button, Modal } from 'react-bootstrap';

import { ReactComponent as StarBlur } from "../images/star-blur.svg";

import redDiamond from '../images/red-diamond.svg';
import diamondsFull from '../images/diamond-box.png';


class Diamonds extends Component {
	
	/* api related code */
	state = {
		loading: true,
		person: null
	};
	
	async componentDidMount() {
		const apiUrl = process.env.REACT_APP_API_URL;
		const url = apiUrl+"/diamonds";
		const response = await fetch(url);
		const data = await response.json();
		this.setState({ diamond: data.results, image_url : data.image_url , loading: false });
	}
	/* api related code */
	
	
	/* modal popup code */
	constructor(props, context) {
		super(props, context);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);

		this.state = {
			show: false,
		};
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}
	/* modal popup code */
	
	confirmation(diamond_info) {

		this.setState({	diamond_info : diamond_info , diamond_currency: diamond_info.currency ,diamond_value : diamond_info.diamond_value , diamond_quantity : diamond_info.quantity , diamond_image : diamond_info.image});
		this.setState({ show: true });
		
	}
	
	diamondPurchase(diamond_info) {
	
		localStorage.setItem('diamond', JSON.stringify(diamond_info));
		localStorage.setItem('diamond_id', diamond_info.id);
		
		window.location.href="diamond#com.squareoff.diamonds."+diamond_info.quantity;
	}
	
	
  render() {
	  
	/* api related code */  
	if (this.state.loading) {
		return <div>Loading...</div>;
    }

    if (!this.state.diamond) {
		return <div>Loading...</div>;
    }
	/* api related code */
	
	  
    return (
		<div className="diamonds-overlay m-t-15">
			
			<div className="coins-left-box display-flex justify-center">
				<img src={diamondsFull} className="diamonds-full" alt="diamond box" />
				<div className="diamonds-left-sub">
					<p className="coins-left-text1 align-left">Diamonds left</p>
					<p className="coins-left-text2 align-left">{localStorage.getItem('diamonds')}</p>
				</div>
			</div>
			
			
			<div className="diamonds-info-overlay display-flex justify-around wrap pad-10 posr">
				{this.state.diamond.map((gem, i) => (
					<div key={gem.id} className="rewards-info-box display-flex justify-center vertical-align-between wrap posr">
						<StarBlur className="star-blur" />
						<StarBlur className="star-blur-1" />
						<div className="wdh">
							<img src={this.state.image_url+gem.image} className="reward-info-pic" alt="redDiamond" />
							<h2 className="info-text-1">{gem.quantity} Diamonds</h2>
						</div>	
						<div className="wdh">
							<Button className="buy-anchor display-flex vertical-align-center justify-center" variant="primary" onClick={() => this.confirmation(gem)}>
								<span className="buy-anchor-span">${gem.diamond_value}</span>
							</Button>
						</div>
					</div>
				))}
			</div>
			
			<Modal className="modal-pop-up diamond-modal" show={this.state.show} onHide={this.handleClose} centered>
				{/* <Modal.Header closeButton></Modal.Header> */}
				<img src={this.state.image_url+this.state.diamond_image} className="modal-pic" alt="img" />
				<h2 className="modal-title">{this.state.diamond_quantity} Diamonds</h2>
				<p className="modal-text">Buy {this.state.diamond_quantity} Diamonds for <span className="bold">{this.state.diamond_currency}{this.state.diamond_value}</span></p>
				<Button className="buy-anchor" variant="primary" onClick={() => this.diamondPurchase(this.state.diamond_info)}>
					Confirm
				</Button>
			</Modal>
			
		</div>
    );
  }
}

export default Diamonds;
